import { useQuery } from "react-query";
import {
  ref,
  query,
  orderByChild,
  startAt,
  endAt
} from "firebase/database";
import { createQuery, db } from "../util/db";

// Subscribe to tour data
export function useTourById(id) {
  return useQuery(
    ["tour", { id }],
    createQuery(() => ref(db, "tours/"+id)),
    { enabled: !!id }
  );
}

// Subscribe to all tours by owner
export function useToursByUserId(userId) {
  return useQuery(
    ["tours", { userId }],
    createQuery(() =>
      query(query(ref(db, 'tours'), orderByChild(`usr/${userId}`)), startAt(0), endAt(5))
    ),
    { enabled: !!userId }
  );
}
import React from "react";
import { Button, Grid } from "@material-ui/core";
import { Field, Form } from "react-final-form";
import * as validators from '../util/validators';
import RenderField from "../components/RenderField";
import ImageUpload from "../components/ImageUpload";
import PricingSection from "../components/PricingSection";

export default function TourForm({ onSubmit, tour }) {
  return (
    <Form
      onSubmit={onSubmit}
      initialValues={tour ? { ...tour } : {}}
      // Plan selection happens outside of a field, validate it here.
      validate={values => {
        const errors = {}
        if (!tour?.id && (!values.plan_index || !values.stripe_price_id)) {
          errors.plan = 'You must select a plan';
        }
        return errors;
      }}
      render={({ handleSubmit, form, values, errors }) => {
        const handleImageUploaded = (path, fieldName) => {
          form.change(fieldName, path);
        }

        const handleChoosePlanClicked = ({ planIndex, stripePriceId }) => {
          form.change('plan_index', planIndex);
          form.change('stripe_price_id', stripePriceId);
        }

        return (
          <form onSubmit={handleSubmit}>
            <Grid container spacing={4}>

              {!values.id && (
                <Grid item xs={12}>
                  <PricingSection 
                    selectedPlanIndex={values.plan_index}
                    onChoosePlanClicked={handleChoosePlanClicked}
                  />

                  {(form.getState().submitFailed && errors.plan) && <span>{errors.plan}</span>}
                </Grid>
              )}

              <Grid item xs={12}>
                <Field
                  name="det.nm"
                  component={RenderField}
                  type="text"
                  placeholder="Name"
                  label="Name"
                  validate={validators.required}
                />
              </Grid>

              <Grid item xs={12}>
                <Field
                  name="det.snm"
                  component={RenderField}
                  type="text"
                  placeholder="Sub name"
                  label="Sub Name"
                  validate={validators.required}
                />
              </Grid>
              
              <Grid item xs={12}>
                <ImageUpload
                  label="Image"
                  placeholder={tour ? tour.det.pURL : null}
                  onFileUploaded={url => handleImageUploaded(url, 'det.pURL')}
                />
              </Grid>

              <Grid item xs={12}>
                <Button 
                  type="submit" 
                  variant="contained"
                  size="medium"
                  color="primary"
                >
                  {tour ? 'Save' : 'Create'}
                </Button>
              </Grid>
            </Grid>
          </form>
        );
      }} 
    />
  );
}
import React from "react";
import { 
  Box, 
  Button, 
  CircularProgress,
  Divider, 
  IconButton, 
  List, 
  ListItem, 
  ListItemSecondaryAction,
  ListItemText, 
  Paper, 
  Typography 
} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import { Link, useRouter } from "../util/router";
import { deleteTour } from "../actions/tour";
import { useAuth } from "../util/auth";

export default function TourList({ tours, toursStatus }) {
  const router = useRouter();
  const auth = useAuth();

  const toursAreEmpty = !tours || tours.length === 0;

  return (
    <Paper>
      <Box
        display="flex"
        justifyContent="space-between"
        alignTours="center"
        padding={2}
      >
        <Typography variant="h5">Tours</Typography>
        
        <Button
          variant="contained"
          size="medium"
          color="primary"
          onClick={() => router.push('/tours/create')}
        >
          Create Tour
        </Button>
      </Box>

      <Divider />

      {(toursStatus === "loading" || toursAreEmpty) && (
        <Box py={5} px={3} align="center">
          {toursStatus === "loading" && <CircularProgress size={32} />}

          {toursStatus !== "loading" && toursAreEmpty && (
            <>Nothing yet. Click the button to add your first tour.</>
          )}
        </Box>
      )}

      {toursStatus !== "loading" && tours && tours.length > 0 && (
        <List disablePadding={true}>
          {tours.map((tour, index) => {
            const role = tour.usr[auth.user.id];
            const canEdit = role === 0 || role === 1;

            return (
              <ListItem
                key={index}
                divider={index !== tours.length - 1}
              >
                <ListItemText>
                  <Button to={`/tours/${tour.id}`} component={Link}>
                    {tour.det.nm}
                  </Button>
                </ListItemText>
                <ListItemText>{tour.det.snm}</ListItemText>
                <ListItemSecondaryAction>
                  {canEdit && (
                    <>
                      <IconButton
                        edge="end"
                        aria-label="update"
                        onClick={() => router.push(`/tours/${tour.id}/edit`)}
                      >
                        <EditIcon />
                      </IconButton>
                      <IconButton
                        edge="end"
                        aria-label="delete"
                        onClick={() => deleteTour(tour.id)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </>
                  )}
                </ListItemSecondaryAction>
              </ListItem>
            )
          })}
        </List>
      )}
    </Paper>
  );
}
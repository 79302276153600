import React from "react";
import { Box, Container } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { useToursByUserId } from "../../query/tour";
import { useAuth } from "../../util/auth";
import Section from "../../components/Section";
import SectionHeader from "../../components/SectionHeader";
import TourList from "../../lists/TourList";

export const Listing = (props) => {
  const auth = useAuth();

  const {
    data: tours,
    status: toursStatus,
    error: toursError,
  } = useToursByUserId(auth.user?.uid);

  return (
    <Section>
      <Container>
        <SectionHeader
          title="Tours"
          size={4}
          textAlign="center"
        />

        {toursError && (
          <Box mb={3}>
            <Alert severity="error">{toursError.message}</Alert>
          </Box>
        )}

        <TourList
          tours={tours}
          toursStatus={toursStatus}
        />
      </Container>
    </Section>
  )
}
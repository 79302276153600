import { useQuery } from "react-query";
import {
  ref,
  query,
  orderByChild,
  equalTo
} from "firebase/database";
import { createQuery, db } from "../util/db";

export function useSubscriptionByTourId(tourId) {
  return useQuery(
    ["subscriptions", { tourId }],
    createQuery(() => 
      query(query(ref(db, 'subscriptions'), orderByChild('tUID')), equalTo(tourId || null))
    ),
    { enabled: !!tourId }
  );
}

export function useSubscriptionByUserId(userId) {
  return useQuery(
    ["subscriptions", { userId }],
    createQuery(() => 
      query(query(ref(db, 'subscriptions'), orderByChild('owner')), equalTo(userId || null))
    ),
    { enabled: !!userId }
  );
}
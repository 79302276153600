import React from "react";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import CheckIcon from "@material-ui/icons/Check";
import ListItemText from "@material-ui/core/ListItemText";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import Section from "./Section";
import SectionHeader from "./SectionHeader";
import { useProducts } from "../query/product";
import { CircularProgress } from "@material-ui/core";

const card = {
  display: "flex",
  flexDirection: "column",
  height: "100%",
}

const useStyles = makeStyles((theme) => ({
  card,
  cardSelected: {
    ...card,
    border: '2px solid',
    borderColor: theme.palette.success.main
  },
  cardContent: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    padding: theme.spacing(3),
  },
  price: {
    display: "flex",
    alignItems: "baseline",
  },
  listItem: {
    paddingTop: 2,
    paddingBottom: 2,
  },
  perkIcon: {
    minWidth: 34,
    color: theme.palette.success.main,
  },
}));

function PricingSection(props) {
  const classes = useStyles();

  const { 
    data: productsData,
    status: productsStatus,
    // error: productsError
  } = useProducts();

  const productsAreEmpty = !productsData || Object.keys(productsData).length === 0;

  return (
    <Section
      bgColor={props.bgColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
    >
      <Container>
        <SectionHeader
          title={props.title}
          subtitle={props.subtitle}
          size={4}
          textAlign="center"
        />

        {productsStatus === 'success' && (
          <Grid container={true} justifyContent="center" spacing={4}>
            {Object.keys(productsData).map(i => {
              const selected = (i === props.selectedPlanIndex);
              const disabled = props.disabledPlans?.includes(i);

              return (
                <Grid item={true} xs={12} md={4} key={i}>
                  
                  <Card className={selected ? classes.cardSelected : classes.card}>
                    <CardContent className={classes.cardContent}>
                      <Typography variant="h6" component="h2">
                        {productsData[i].nm}
                      </Typography>
                      <Box className={classes.price} mt={1}>
                        <Typography variant="h3">${productsData[i].price}.00</Typography>
                        <Typography variant="h4" color="textSecondary">
                          /mo
                        </Typography>
                      </Box>

                      {productsData[i].description && (
                        <Box mt={2}>
                          <Typography component="p" color="textSecondary">
                            {productsData[i].description}
                          </Typography>
                        </Box>
                      )}

                      <Box mt={1}>
                        <List aria-label="perks">
                          <ListItem
                            className={classes.listItem}
                            disableGutters={true}
                          >
                            <ListItemIcon className={classes.perkIcon}>
                              <CheckIcon />
                            </ListItemIcon>
                            <ListItemText>{`${productsData[i].usr} Users`}</ListItemText>
                          </ListItem>

                          <ListItem
                            className={classes.listItem}
                            disableGutters={true}
                          >
                            <ListItemIcon className={classes.perkIcon}>
                              <CheckIcon />
                            </ListItemIcon>
                            <ListItemText>{`${productsData[i].pck} Packs`}</ListItemText>
                          </ListItem>

                          <ListItem
                            className={classes.listItem}
                            disableGutters={true}
                          >
                            <ListItemIcon className={classes.perkIcon}>
                              <CheckIcon />
                            </ListItemIcon>
                            <ListItemText>{`${productsData[i].itm} Items`}</ListItemText>
                          </ListItem>
                        </List>
                      </Box>

                      <Box mt="auto" pt={3}>
                        <Button
                          onClick={() => props.onChoosePlanClicked({ planIndex: i, stripePriceId: productsData[i].stripe_price_id })}
                          variant="contained"
                          color="primary"
                          size="large"
                          fullWidth={true}
                          disabled={selected || disabled}
                        >
                          {disabled ? 'Current Plan' : (selected ? 'Selected' : 'Choose')}
                        </Button>
                      </Box>
                    </CardContent>
                  </Card>
                </Grid>
              );
            })}
          </Grid>
        )}

        {(productsStatus === "loading" || productsAreEmpty) && (
          <Box py={5} px={3} align="center">
            {productsStatus === "loading" && <CircularProgress size={32} />}

            {productsStatus !== "loading" && productsAreEmpty && (
              <>Failed to load products</>
            )}
          </Box>
        )}
      </Container>
    </Section>
  );
}

export default PricingSection;

import React, { useState } from "react";
import Meta from "./../components/Meta";
import { Button, CircularProgress, Container, Grid, Paper, TextField } from "@material-ui/core";
import { useAuth } from "../util/auth";
import { useForm } from "react-hook-form";
import Section from "../components/Section";
import SectionHeader from "../components/SectionHeader";
import { useRouter } from "../util/router";

function CompleteAccountPage() {
  const auth = useAuth();
  const router = useRouter();

  const [pending, setPending] = useState(false);

  const { register, handleSubmit, errors } = useForm();

  const onSubmit = (data) => {
    // Show pending indicator
    setPending(true);

    return auth
      .updateProfile(data)
      .then(() => {
        router.push('/dashboard');
      })
      .catch((error) => {
        console.log(error)
      });
  };


  return (
    <>
      <Meta title="Complete Account" />

      <Section>
        <Container>
          <SectionHeader
            title="Complete account"
            size={4}
            textAlign="center" 
          />
            <form onSubmit={handleSubmit(onSubmit)}>
              <Grid container={true} spacing={2}>
              
                <Grid item={true} xs={12}>
                  <TextField
                    variant="outlined"
                    type="phone"
                    label="Phone number"
                    name="phone"
                    defaultValue={auth.user.pvt?.phn}
                    error={errors.phone ? true : false}
                    helperText={errors.phone && errors.phone.message}
                    fullWidth={true}
                    inputRef={register({
                      required: "Please enter your phone number",
                    })}
                  />
                </Grid>

                <Grid item={true} xs={12}>
                  <Button
                    variant="contained"
                    color="primary"
                    size="large"
                    type="submit"
                    disabled={pending}
                    fullWidth={true}
                  >
                    {!pending && <span>Complete Account</span>}

                    {pending && <CircularProgress size={28} />}
                  </Button>
                </Grid>
              </Grid>
            </form>

            <Paper>
              <Container></Container>
            </Paper>
          </Container>
        </Section>
    </>
  );
}

export default CompleteAccountPage;
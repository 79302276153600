import { useQuery } from "react-query";
import {
  ref,
  query,
  orderByChild,
  startAt,
  endAt,
  equalTo
} from "firebase/database";
import { createQuery, db } from "../util/db";

// Subscribe to item data
export function useItemById(id) {
  return useQuery(
    ["item", { id }],
    createQuery(() => ref(db, "items/"+id)),
    { enabled: !!id }
  );
}

// Subscribe to all items by owner
export function useItemsByUserId(userId) {
  return useQuery(
    ["items", { userId }],
    createQuery(() =>
      query(query(ref(db, 'items'), orderByChild(`usr/${userId}`)), startAt(0), endAt(5))
    ),
    { enabled: !!userId }
  );
}

// Subscribe to all items by pack
export function useItemsByPackId(packId, userId) {
  return useQuery(
    ["items", { packId }],
    createQuery(() =>
      query(query(ref(db, 'items'), orderByChild(`pck/${packId}`)), equalTo(userId))
    ),
    { enabled: !!packId }
  );
}
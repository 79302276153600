import React from "react";
import ItemForm from "../../forms/ItemForm";
import Section from "../../components/Section";
import { CircularProgress, Container, Paper } from "@material-ui/core";
import SectionHeader from "../../components/SectionHeader";
import { useParams } from "react-router-dom";
import { useItemById } from "../../query/item";
import { createItem, updateItem } from "../../actions/item";
import { useAuth } from "../../util/auth";
import { updateUserItems } from "../../actions/user";

export const AddEdit = ({ history }) => {
  const params = useParams();
  const auth = useAuth(); 

  const {
    data: item, 
    status: itemStatus,
    // error: itemError
  } = useItemById(params.id);

  const handleSubmit = async (values) => {
    const formValues = { 
      ...values,
      usr: { [`${auth.user.uid}`]: 0 },
      pck: {}
    }

    const round = n => Math.round(n * 100) / 100;

    if (!values.det.dM) {
      formValues['det']['dW'] = round(parseFloat(values.det.dW) * 0.453592);
      formValues['det']['dX'] = round(parseFloat(values.det.dX) * 25.4);
      formValues['det']['dY'] = round(parseFloat(values.det.dY) * 25.4);
      formValues['det']['dZ'] = round(parseFloat(values.det.dZ) * 25.4);
      formValues['det']['dM'] = false;
    } else {
      formValues['det']['dW'] = round(parseFloat(values.det.dW));
      formValues['det']['dX'] = round(parseFloat(values.det.dX));
      formValues['det']['dY'] = round(parseFloat(values.det.dY));
      formValues['det']['dZ'] = round(parseFloat(values.det.dZ));
    }

    formValues['det']['pURL'] = formValues.pURL || '';

    if (formValues.pURL) {
      delete formValues.pURL;
    }

    // Temporary
    formValues['det']['st'] = 0;
    formValues['det']['trust'] = "";

    if (params.id) {
      delete formValues.id;
      await updateItem(params.id, formValues);
    } else {
      formValues['det']['own'] = auth.user.uid;

      const r = await createItem(formValues);
      await updateUserItems(auth.user.uid, r.key);
    }

    history.goBack();
  };

  return (
    <Section>
      <Container>
        <SectionHeader
          title={item?.det ? `Edit ${item.det.nm}` : 'Create Item'}
          // subtitle={props.subtitle}
          size={4}
          textAlign="center" 
        />

        {params.id && itemStatus === 'loading' ? (
          <CircularProgress />
        ) : (
          <Paper>
            <Container>
              <ItemForm
                onSubmit={handleSubmit}
                item={params.id ? item : null}
              />
            </Container>
          </Paper>
        )}
      </Container>
    </Section>
  );
};

import React from "react";
import { 
  Box, 
  Button, 
  CircularProgress,
  Divider, 
  IconButton, 
  List, 
  ListItem, 
  ListItemSecondaryAction,
  ListItemText, 
  Paper, 
  Typography 
} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import { Link, useRouter } from "../util/router";
import { deletePack } from "../actions/pack";

export default function PackList({ packs, packsStatus, actionsDisabled, tourId }) {
  const router = useRouter();

  const packsAreEmpty = !packs || packs.length === 0;

  const handleDeletePackClicked = packId => {
    // eslint-disable-next-line no-restricted-globals
    if (confirm('Are you sure you want to delete this pack?')) {
      deletePack(packId);
    }
  }

  return (
    <Paper>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        padding={2}
      >
        <Typography variant="h5">Packs</Typography>
        
        <Button
          variant="contained"
          size="medium"
          color="primary"
          onClick={() => router.push(`/packs/create?tour_id=${tourId}`)}
          disabled={actionsDisabled}
        >
          Create Pack
        </Button>
      </Box>

      <Divider />

      {(packsStatus === "loading" || packsAreEmpty) && (
        <Box py={5} px={3} align="center">
          {packsStatus === "loading" && <CircularProgress size={32} />}

          {packsStatus !== "loading" && packsAreEmpty && (
            <>Nothing yet. Click the button to add your first item.</>
          )}
        </Box>
      )}

      {packsStatus !== "loading" && packs && packs.length > 0 && (
        <List disablePadding={true}>
          {packs.map((pack, index) => (
            <ListItem
              key={index}
              divider={index !== packs.length - 1}
            >
              <ListItemText>
                <Button to={`/packs/${pack.id}`} component={Link} disabled={actionsDisabled}>
                  {pack.det.nm}
                </Button>
              </ListItemText>
              <ListItemText>{pack.det.snm}</ListItemText>
              <ListItemSecondaryAction>
                <IconButton
                  edge="end"
                  aria-label="update"
                  onClick={() => router.push(`/packs/${pack.id}/edit`)}
                  disabled={actionsDisabled}
                >
                  <EditIcon />
                </IconButton>
                <IconButton
                  edge="end"
                  aria-label="delete"
                  onClick={() => handleDeletePackClicked(pack.id)}
                  disabled={actionsDisabled}
                >
                  <DeleteIcon />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          ))}
        </List>
      )}
    </Paper>
  );
}
import React from "react";
import Section from "../../components/Section";
import { Box, CircularProgress, Container } from "@material-ui/core";
import SectionHeader from "../../components/SectionHeader";
import { usePackById } from "../../query/pack";
import { useParams } from "react-router-dom";
import { Alert } from "@material-ui/lab";
import { useItemsByPackId } from "../../query/item";
import ItemList from "../../lists/ItemList";
import { useAuth } from "../../util/auth";

export const View = () => {
  
  const params = useParams();
  const auth = useAuth();

  const {
    data: pack,
    status: packStatus,
    error: packError
  } = usePackById(params.id);

  const {
    data: items,
    status: itemsStatus,
    error: itemsError
  } = useItemsByPackId(params.id, auth.user?.id);

  return (
    <Section>
      <Container>
        {(packStatus === "loading" || !pack) && (
          <Box py={5} px={3} align="center">
            {packStatus === "loading" && <CircularProgress size={32} />}

            {packStatus !== "loading" && (
              <>Nothing yet. Click the button to add your first item.</>
            )}
          </Box>
        )}

        {packError && (
          <Box mb={3}>
            <Alert severity="error">{packError.message}</Alert>
          </Box>
        )}

        {packStatus === 'success' && pack && (
          <>
            <SectionHeader
              title={pack.det.nm}
              size={4}
              textAlign="center" 
            />

            {itemsError && (
              <Box mb={3}>
                <Alert severity="error">{itemsError.message}</Alert>
              </Box>
            )}

            <ItemList
              items={items}
              itemsStatus={itemsStatus}
            />
          </>
        )}
      </Container>
    </Section>
  );
}
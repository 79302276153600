/* eslint-disable no-restricted-globals */
import React, { useState } from "react";
import Section from "../../components/Section";
import { Box, Button, CircularProgress, Container, Paper, Typography } from "@material-ui/core";
import SectionHeader from "../../components/SectionHeader";
import { useTourById } from "../../query/tour";
import { useParams } from "react-router-dom";
import { usePacksByTourId } from "../../query/pack";
import PackList from "../../lists/PackList";
import { Alert } from "@material-ui/lab";
import { useSubscriptionByTourId, useSubscriptionByUserId } from "../../query/subscription";
import { useProducts } from "../../query/product";
import SubscriptionForm from "../../forms/SubscriptionForm";
import { cancelSubscription, redirectToCheckout, renewSubscription, updateSubscription } from "../../util/stripe";
import { updateTour } from "../../actions/tour";
import { Stack } from "@mui/material";
import Modal from "../../components/Modal";
import { useAuth } from "../../util/auth";

export const View = () => {
  const [showSubscriptionForm, setShowSubscriptionForm] = useState(false);
  const [showChangeSubscriptionForm, setShowChangeSubscriptionForm] = useState(false);  

  const params = useParams();
  const auth = useAuth();
  const customerId = auth.user?.stripe_customer_id;

  const {
    data: tour,
    status: tourStatus,
    error: tourError
  } = useTourById(params.id);

  const {
    data: packs,
    status: packsStatus,
    error: packsError
  } = usePacksByTourId(params.id);

  const {
    data: tourSubscriptions,
    status: tourSubscriptionStatus
  } = useSubscriptionByTourId(tour?.id);

  const {
    data: subscriptions
  } = useSubscriptionByUserId(auth.user?.uid);

  const { 
    data: products,
    status: productsStatus
  } = useProducts();

  const handleRenewSubscriptionClicked = () => {
    setShowSubscriptionForm(true);
  }

  const handleSubscriptionFormSubmitted = values => {
    if (customerId && subscriptions) {
      renewSubscription(customerId, values.stripe_price_id, tour.id);
    } else {
      redirectToCheckout(values.stripe_price_id, tour.id, values.plan_index);
    }
  }

  const handleChangeSubscriptionFormSubmitted = values => {
    if (!tourSubscriptions?.length) return;

    updateSubscription(
      tourSubscriptions[0].stripe_subscription_id, 
      tourSubscriptions[0].stripe_subscription_item_id, 
      values.stripe_price_id,
    ).then((response) => {
      updateTour(tour.id, {
        ...tour,
        plan_index: values.plan_index
      });
    });
  }

  const handleCancelPlanClicked = () => {
    if (tourSubscriptions?.length && confirm('Are you sure you want to cancel your plan for this tour?')) {
      cancelSubscription(tourSubscriptions[0].stripe_subscription_item_id);
    }
  }

  const role = tour?.usr[auth.user.id];
  const canEdit = role === 0 || role === 1;
  
  return (
    <Section>
      <Container>

        {(tourStatus === "loading" || !tour) && (
          <Box py={5} px={3} align="center">
            {tourStatus === "loading" && <CircularProgress size={32} />}

            {tourStatus !== "loading" && (
              <>Nothing yet. Click the button to add your first item.</>
            )}
          </Box>
        )}

        {tourError && (
          <Box mb={3}>
            <Alert severity="error">{tourError.message}</Alert>
          </Box>
        )}

        {tourStatus === 'success' && tour && (
          <>
            <SectionHeader
              title={tour.det?.nm}
              size={4}
              textAlign="center" 
            />
            
            {packsError && (
              <Box mb={3}>
                <Alert severity="error">{packsError.message}</Alert>
              </Box>
            )}

            {tourSubscriptionStatus === 'success' && canEdit && (
              !tourSubscriptions?.length > 0 ? (
                <Box mb={3}>
                  <Paper>
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                      padding={2}
                    >
                      <Typography variant="h5">No active subscription</Typography>
                      
                      <Button
                        variant="contained"
                        size="medium"
                        color="primary"
                        onClick={handleRenewSubscriptionClicked}
                      >
                        Renew Subscription
                      </Button>
                    </Box>
                  </Paper>
                </Box>
              ) : (
                <Box mb={3}>
                  <Paper>
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                      padding={2}
                    >
                      {productsStatus === 'success' && tourSubscriptions?.length > 0 && (
                        <Typography variant="h5">
                          Plan: {products[tour.plan_index]?.nm} {tourSubscriptions[0].stripe_status}
                        </Typography>
                      )}
                      
                      <Stack direction="row" spacing={2}>
                        <Button
                          variant="contained"
                          size="medium"
                          color="primary"
                          onClick={() => setShowChangeSubscriptionForm(true)}
                        >
                          Change plan
                        </Button>
                        <Button
                          variant="contained"
                          size="medium"
                          color="secondary"
                          onClick={handleCancelPlanClicked}
                        >
                          Cancel plan
                        </Button>
                      </Stack>
                    </Box>
                  </Paper>
                </Box>
              )
            )}

            {tourSubscriptionStatus !== 'loading' && tourSubscriptions?.length > 0 && (
              <PackList
                packs={packs}
                packsStatus={packsStatus}
                actionsDisabled={!tourSubscriptions[0]?.stripe_status === 'active'}
                tourId={tour.id}
              />
            )}
          </>
        )}
      </Container>

      <Modal 
        title="Renew Subscription" 
        open={showSubscriptionForm} 
        onClose={() => setShowSubscriptionForm(false)}
      >
        <SubscriptionForm 
          onSubmit={handleSubscriptionFormSubmitted} 
          newCustomer={!customerId}
        />
      </Modal>

      <Modal 
        title="Change Subscription" 
        open={showChangeSubscriptionForm} 
        onClose={() => setShowChangeSubscriptionForm(false)}
      >
        <SubscriptionForm 
          onSubmit={handleChangeSubscriptionFormSubmitted}
          subscribedPlanIndex={tour?.plan_index}
          newCustomer={!customerId}
        />
      </Modal>

    </Section>
  );
}
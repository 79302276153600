import { Button, Grid } from "@material-ui/core";
import React from "react";
import { Form } from "react-final-form";
import PricingSection from "../components/PricingSection";

export default function SubscriptionForm({ onSubmit, pack, subscribedPlanIndex, newCustomer }) {
  return (
    <Form
      onSubmit={onSubmit}
      initialValues={pack ? { ...pack } : {}}
      render={({ handleSubmit, form, values }) => {
        const handleChoosePlanClicked = ({ planIndex, stripePriceId }) => {
          form.change('plan_index', planIndex);
          form.change('stripe_price_id', stripePriceId);
        }

        return (
          <form onSubmit={handleSubmit}>
            <Grid container spacing={4}>
              <Grid item xs={12}>
                <PricingSection 
                  selectedPlanIndex={values.plan_index} 
                  onChoosePlanClicked={handleChoosePlanClicked}
                  disabledPlans={subscribedPlanIndex}
                />
              </Grid>

              <Grid item xs={12}>
                <Button 
                  type="submit" 
                  variant="contained"
                  size="medium"
                  color="primary"
                >
                  {newCustomer ? 'Continue to Checkout' : 'Update Subscription'}
                </Button>
              </Grid>
            </Grid>
          </form>
        );
      }} 
    />
  );
}
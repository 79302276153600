import React from "react";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Section from "../components/Section";
import SectionHeader from "../components/Section";
import { useRouter } from "../util/router";
import { requireAuth, useAuth } from "../util/auth";
import { useToursByUserId } from "../query/tour";
import ItemList from "../lists/ItemList";
import TourList from "../lists/TourList";
import { useItemsByUserId } from "../query/item";

function DashboardPage(props) {
  const auth = useAuth();

  const {
    data: tours,
    status: toursStatus,
    error: toursError,
  } = useToursByUserId(auth.user?.uid);

  const {
    data: items,
    status: itemsStatus,
    error: itemsError
  } = useItemsByUserId(auth.user?.uid);

  return (
    <Section
      bgColor={props.bgColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
    >
      <Container>
        <SectionHeader
          title={props.title}
          subtitle={props.subtitle}
          size={4}
          textAlign="center"
        />

        <Grid container={true} spacing={4}>
          <Grid item={true} xs={12} md={6}>
            <TourList
              tours={tours}
              toursStatus={toursStatus}
            />
          </Grid>
          <Grid item={true} xs={12} md={6}>
            <ItemList
              items={items}
              itemsStatus={itemsStatus}
            />
          </Grid>
        </Grid>
      </Container>
    </Section>
  );
}

export default requireAuth(DashboardPage);

import { Container, FormLabel, Paper, Typography } from "@material-ui/core";
import React, { useState } from "react";
import Dropzone from "react-dropzone";
import { uploadFile, getUrl } from "../util/storage";
import ImageIcon from '@mui/icons-material/Image';
import { Stack } from "@mui/material";

export default function ImageUpload({ onFileUploaded, label, placeholder = null }) {
  const [uploadedUrl, setUploadedUrl] = useState(placeholder);

  const handleDrop = (acceptedFiles) => {
    const file = acceptedFiles[0];

    const [name, ext] = file.name.split('.');
    const fileName = `${name.replace(' ', '_')}_${Date.now()}.${ext}`; 

    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = e => {
      uploadFile(e.target.result, fileName).then(snapshot => {
        const newPath = snapshot.metadata.fullPath;
        
        getUrl(newPath).then(url => {
          setUploadedUrl(url);
          onFileUploaded(url);
        });
      });
    }
  }

  return (
    <Container disableGutters>
      <Stack direction="column" gap={2}>
        <FormLabel>{label}</FormLabel>
        <Dropzone onDrop={handleDrop}>
          {({ getRootProps, getInputProps }) => (
            <Paper {...getRootProps()}>
              <Stack direction="column" justifyContent="center" padding={6}>
                {uploadedUrl ? (
                  <Stack direction="row" justifyContent="center">
                    <img src={uploadedUrl} alt="img" style={{ maxWidth: '10rem' }} />
                  </Stack>
                ) : (
                  <>
                    <Stack direction="row" justifyContent="center">
                      <ImageIcon fontSize="large" />
                    </Stack>

                    <Stack direction="row" justifyContent="center">
                      <Typography>Upload Image</Typography>
                    </Stack>
                  </>
                )}
              </Stack>
              <input {...getInputProps()} />
            </Paper>
          )}
        </Dropzone>
      </Stack>
    </Container>
  );
}
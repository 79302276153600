import React from  'react';
import TextField from './Fields/TextField';
import CheckboxField from './Fields/CheckboxField';
import SelectField from './Fields/SelectField';
import DateTimeField from './Fields/DateTimeField';
import SwitchField from './Fields/SwitchField';
import MultiSelectField from './Fields/MultiSelectField';

export default function RenderField(props) {
  switch (props.input.type) {
    case 'checkbox':
      return <CheckboxField {...props} />
    case 'select':
      return <SelectField {...props} />
    case 'multiselect':
      return <MultiSelectField {...props} />
    case 'text':
      return <TextField {...props} />
    case 'datetime':
      return <DateTimeField {...props} />
    case 'switch':
      return <SwitchField {...props} />
    default:
      return <TextField {...props} />
  }
};
import { ref, remove, serverTimestamp, push, set, get, child } from "firebase/database";
import { db, formatSingleDoc } from "../util/db";

export async function createTour(data) {
  const tourRef = await push(ref(db, "tours"), {
    ...data,
    createdAt: serverTimestamp(),
  });

  const dataSnapshot = await get(tourRef);

  return formatSingleDoc(dataSnapshot);
}

export function updateTour(id, data) {
  return set(ref(db, "tours/"+id), data);
}

export function deleteTour(id) {
  return remove(ref(db, "tours/"+id));
}

export async function updateTourPack(tuid, puid, value) {
  return set(child(ref(db, `tours/${tuid}`),`pck/${puid}`), value);
}
import React from "react";
import TourForm from "../../forms/TourForm";
import Section from "../../components/Section";
import { CircularProgress, Container } from "@material-ui/core";
import SectionHeader from "../../components/SectionHeader";
import { useParams } from "react-router-dom";
import { useTourById } from "../../query/tour";
import { createTour, updateTour } from "../../actions/tour";
import { useAuth } from "../../util/auth";
import { redirectToCheckout } from "../../util/stripe";
import { updateUserTour } from "../../actions/user";

export const AddEdit = ({ history }) => {
  const params = useParams();
  const auth = useAuth(); 

  const {
    data: tour, 
    status: tourStatus,
    // error: tourError
  } = useTourById(params.id);

  const handleSubmit = async (values) => {
    const formValues = { 
      ...values,
      usr: { [`${auth.user.uid}`]: 0 }
    }

    // We only need stripe price id for redirect, don't save in db
    delete formValues['stripe_price_id'];
    
    if (params.id) {
      delete formValues.id;
      await updateTour(params.id, formValues);
    } else {
      formValues.det['own'] = auth.user.uid;
      formValues.det['st'] = true;
      formValues.det['sty'] = 0;

      if (!formValues.det.pURL) {
        formValues.det['pURL'] = '';
      }

      const tour = await createTour(formValues);
      await updateUserTour(auth.user.uid, tour.id, 1);

      redirectToCheckout(values.stripe_price_id, tour.id, values.plan_index);
    }

    history.goBack();
  };

  return (
    <Section>
      <Container>
        <SectionHeader
          // title={props.title}
          // subtitle={props.subtitle}
          size={4}
          textAlign="center" 
        />

        {params.id && tourStatus === 'loading' ? (
          <CircularProgress />
        ) : (
          <TourForm
            onSubmit={handleSubmit}
            tour={params.id ? tour : null} />
        )}
      </Container>
    </Section>
  );
};

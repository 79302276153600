import React from "react";
import { FormGroup, FormLabel, ListItemText, MenuItem, Select } from "@material-ui/core";
import Checkbox from '@mui/material/Checkbox';

export default function MultiSelectField({ input, label, options, placeholder, meta: { touched, error } }) {
  const isError = touched && typeof error !== 'undefined';

  const optionsByValue = options.reduce((p, c) => {
    p[c.value] = c.label;
    return p;
  },{})

  return (
    <FormGroup>
      {label && <FormLabel>{label}</FormLabel>}

      <Select
        multiple
        value={input.value || []}
        onChange={input.onChange}
        placeholder={placeholder}
        renderValue={selected => {
          return selected.length > 1
            ? selected.map(s => optionsByValue[s]).join(', ')
            : optionsByValue[selected[0]]
        }}
      >
        {options.map((option, i) => (
          <MenuItem key={i} value={option.value}>
            <Checkbox checked={input.value.indexOf(option.value) !== -1} />
            <ListItemText primary={option.label} />
          </MenuItem>
        ))}
      </Select>

      {isError && <span>{error}</span>}
    </FormGroup>
  );
}
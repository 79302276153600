import {
  useQuery,
  hashQueryKey,
  QueryClient,
  QueryClientProvider as QueryClientProviderBase,
} from "react-query";

import {
  getDatabase,
  onValue,
  ref,
  set,
  child,
} from "firebase/database";

import { firebaseApp } from "./firebase";

// Initialize Realtime DB
export const db = getDatabase(firebaseApp);

// React Query client
const client = new QueryClient();

/**** USERS ****/

// Subscribe to user data
// Note: This is called automatically in `auth.js` and data is merged into `auth.user`
export function useUser(uid) {
  // Manage data fetching with React Query: https://react-query.tanstack.com/overview
  return useQuery(
    // Unique query key: https://react-query.tanstack.com/guides/query-keys
    ["user", { uid }],
    // Query function that subscribes to data and auto-updates the query cache
    createQuery(() => ref(db, "users/"+uid)),
    // Only call query function if we have a `uid`
    { enabled: !!uid }
  );
}

export async function updateRecord(path, data) {
  Object.keys(data).forEach(async key => {
    await set(child(ref(db, path), key), data[key]);
  });
}

/**** HELPERS ****/

// Store Firestore unsubscribe functions
const unsubs = {};

export function createQuery(getRef) {
  // Create a query function to pass to `useQuery`
  return async ({ queryKey }) => {
    let unsubscribe;
    let firstRun = true;
    // Wrap `onValue` with a promise so that we can return initial data
    const data = await new Promise((resolve, reject) => {
      unsubscribe = onValue(
        getRef(),
        // Success handler resolves the promise on the first run.
        // For subsequent runs we manually update the React Query cache.
        (response) => {
          const data = format(response, queryKey);

          if (firstRun) {
            firstRun = false;
            resolve(data);
          } else {
            client.setQueryData(queryKey, data);
          }
        },
        // Error handler rejects the promise on the first run.
        // We can't manually trigger an error in React Query, so on a subsequent runs we
        // invalidate the query so that it re-fetches and rejects if error persists.
        (error) => {
          if (firstRun) {
            firstRun = false;
            reject(error);
          } else {
            client.invalidateQueries(queryKey);
          }
        }
      );
    });

    // Unsubscribe from an existing subscription for this `queryKey` if one exists
    // Then store `unsubscribe` function so it can be called later
    const queryHash = hashQueryKey(queryKey);
    unsubs[queryHash] && unsubs[queryHash]();
    unsubs[queryHash] = unsubscribe;

    return data;
  };
}

// Automatically remove Firestore subscriptions when all observing components have unmounted
client.queryCache.subscribe(({ type, query }) => {
  if (
    type === "observerRemoved" &&
    query.getObserversCount() === 0 &&
    unsubs[query.queryHash]
  ) {
    // Call stored Firestore unsubscribe function
    unsubs[query.queryHash]();
    delete unsubs[query.queryHash];
  }
});

export const formatSingleDoc = (doc) => {
  return { id: doc.key, ...doc.val() }
}

const formatNestedDoc = (key, data) => {
  return { id: key, ...data }
}

const formatNestedDocs = (response) => {
  const data = response.val();

  // Check if single doc has multiple ids
  const ids = Object.keys(data);

  return ids.length > 1 
    ? ids.map(k => formatNestedDoc(k, data[k])) 
    : [formatNestedDoc(ids[0], data[ids[0]])]
}

// Format Firestore response
function format(response, queryKey) {
  if (response.docs) {
    // Handle a collection of docs
    return response.docs.map(formatSingleDoc);
  } else if (response.exists()) {
    const res = response.key === queryKey[0]
      ? formatNestedDocs(response)
      : formatSingleDoc(response);

    return res;
  }

  return null;
}

// React Query context provider that wraps our app
export function QueryClientProvider(props) {
  return (
    <QueryClientProviderBase client={client}>
      {props.children}
    </QueryClientProviderBase>
  );
}

import React from "react";
import PackForm from "../../forms/PackForm";
import Section from "../../components/Section";
import { CircularProgress, Container } from "@material-ui/core";
import SectionHeader from "../../components/SectionHeader";
import { useParams } from "react-router-dom";
import { usePackById } from "../../query/pack";
import { createPack, updatePack } from "../../actions/pack";
import { useAuth } from "../../util/auth";
import { updateUserPack } from "../../actions/user";
import { updateTourPack } from "../../actions/tour";

export const AddEdit = ({ history }) => {
  const searchParams = new URLSearchParams(window.location.search)

  const params = useParams();
  const auth = useAuth(); 

  const {
    data: pack, 
    status: packStatus,
    // error: packError
  } = usePackById(params.id);

  const handleSubmit = async (values) => {
    const formValues = {
      ...values,
      usr: { [`${auth.user.uid}`]: 0 }
    }
    
    let packId;

    if (params.id) {
      delete formValues.id;

      await updatePack(params.id, formValues);
      packId = params.id;
    } else {
      formValues.det['own'] = auth.user.uid;
      formValues.det['cat'] = '';
      formValues.det['sUID'] = '';
      formValues.det['tUID'] = searchParams.get('tour_id');

      const r = await createPack(formValues);
      packId = r.key;
    }

    // Add records for iOS app
    await updateUserPack(auth.user.uid, values.det.tUID, packId, 1);
    await updateTourPack(values.det.tUID, packId, values.det.nm);

    history.goBack();
  };

  return (
    <Section>
      <Container>
        <SectionHeader
          // title={props.title}
          // subtitle={props.subtitle}
          size={4}
          textAlign="center" 
        />

        {params.id && packStatus === 'loading' ? (
          <CircularProgress />
        ) : (
          <PackForm
            onSubmit={handleSubmit}
            pack={params.id ? pack : null} 
            tourId={searchParams.get('tour_id')}  
          />
        )}
      </Container>
    </Section>
  );
};

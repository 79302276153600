import React from "react";
import Container from "@material-ui/core/Container";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Typography from "@material-ui/core/Typography";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { makeStyles } from "@material-ui/core/styles";
import Section from "./Section";
import SectionHeader from "./SectionHeader";

const useStyles = makeStyles((theme) => ({
  accordion: {
    // Remove shadow
    boxShadow: "none",
    "&:before": {
      // Remove default divider
      display: "none",
    },
    // Add a custom border
    "&:not(:last-child)": {
      borderBottom: `1px solid ${theme.palette.divider}`,
    },
  },
  expanded: {
    margin: `0 !important`,
  },
  summary: {
    minHeight: 78,
  },
  summaryContent: {
    margin: "0 !important",
  },
}));

function FaqSection(props) {
  const classes = useStyles();

  const items = [
    {
      question: "How do I get started?",
      answer:
        "Sign up here or via the iOS app. Creating up to 20 items is free, and you can scan your own items too. Users don’t pay for an account or to be apart of a tour.",
    },
    {
      question: "How do I start a new tour?",
      answer: (
        <span>
        Only from our website - app.loadoutapp.com<br></br>Sign in there, and create a new tour. Your details are safe as we use Stripe to process your credit card and subscription.
        </span>
      )
    },
    {
      question: "How do I join a tour?",
      answer:
        "On your iOS device, sign into LoadOut and have an admin or power user of a tour add you by scanning your QR code from the LoadOut App.",
    },
    {
      question: "Why no in-app-purchases?",
      answer:
        "We’d happily use the built in offerings however multiple purchases of the same product isn’t supported - ie purchasing a medium pack for band a, and another medium pack for band b, you can’t… So it’s much easier to just have manage tours and subscriptions here.",
    },
    {
      question: "What does a subscription include?",
      answer: (
        <span>
          Depending on the size of the tour, you’ll get different amounts of items and users.<br /><br />
          All tiers have unlimited sessions and scans.
        </span>
      ),
    }
  ];

  return (
    <Section
      bgColor={props.bgColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
    >
      <Container maxWidth="md">
        <SectionHeader
          title={props.title}
          subtitle={props.subtitle}
          size={4}
          textAlign="center"
        />

        {items.map((item, index) => (
          <Accordion
            classes={{
              root: classes.accordion,
              expanded: classes.expanded,
            }}
            key={index}
          >
            <AccordionSummary
              classes={{
                root: classes.summary,
                content: classes.summaryContent,
              }}
              expandIcon={<ExpandMoreIcon />}
              aria-controls={`faq-panel-${index}`}
            >
              <Typography variant="h6">{item.question}</Typography>
            </AccordionSummary>
            <AccordionDetails id={`faq-panel-${index}`}>
              <Typography>{item.answer}</Typography>
            </AccordionDetails>
          </Accordion>
        ))}
      </Container>
    </Section>
  );
}

export default FaqSection;

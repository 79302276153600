import React from "react";
import MaterialTextField from "@material-ui/core/TextField";
import { FormGroup, FormLabel } from "@material-ui/core";

export default function TextField({ input, autocomplete, required, label, placeholder, hint, disabled, meta: { error, touched } }) {
  const isError = touched && typeof error !== 'undefined';

  return <FormGroup>
    {label && <FormLabel>{label}</FormLabel>}

    <MaterialTextField
      {...input}
      variant="outlined"
      autoComplete={autocomplete}
      required={required}
      placeholder={placeholder}
      disabled={disabled}
      error={isError}
    />

    {hint && <small>{hint}</small>}

    {isError && <span>{error}</span>}
  </FormGroup>
};
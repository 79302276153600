import React from "react";
import { FormGroup, FormLabel, MenuItem, Select } from "@material-ui/core";

export default function SelectField({ input, label, options, placeholder, meta: { touched, error } }) {
  const isError = touched && typeof error !== 'undefined';

  return (
    <FormGroup>
      {label && <FormLabel>{label}</FormLabel>}

      <Select
        value={input.value}
        onChange={input.onChange}
        placeholder={placeholder}
      >
        {options.map((option, i) => (
          <MenuItem key={i} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>

      {isError && <span>{error}</span>}
    </FormGroup>
  );
}
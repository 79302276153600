import { useQuery } from "react-query";
import {
  ref,
  query,
  orderByChild,
  equalTo,
  startAt,
  endAt
} from "firebase/database";
import { createQuery, db } from "../util/db";

// Subscribe to pack data
export function usePackById(id) {
  return useQuery(
    ["pack", { id }],
    createQuery(() => ref(db, "packs/"+id)),
    { enabled: !!id }
  );
}

//Subscribe to all packs by owner
export function usePacksByUserId(userId) {
  return useQuery(
    ["packs", { userId }],
    createQuery(() =>
      query(query(ref(db, 'packs'), orderByChild(`usr/${userId}`)), startAt(0), endAt(5))
    ),
    { enabled: !!userId }
  );
}

// Subscribe to all packs by tour
export function usePacksByTourId(tourId) {
  return useQuery(
    ["packs", { tourId }],
    createQuery(() =>
      query(query(ref(db, 'packs'), orderByChild(`det/tUID`)), equalTo(tourId))
    ),
    { enabled: !!tourId }
  );
}
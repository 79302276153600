import React from "react";

export default function DateTimeField({ input, label, min, max, meta: { error, touched } }) {
  const isError = touched && typeof error !== 'undefined';

  return <div className="mb-4">
    <label>{label}</label>
    <input 
      type="datetime-local" 
      min={min}
      max={max}
      onChange={input.onChange} 
      value={input.value} 
      className={`rounded w-full ${isError ? 'border-red-600' : 'border-gray-400'}`}
    />

    {isError && <span>{error}</span>}
  </div>
}
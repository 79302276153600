import { getDownloadURL, getStorage, ref, uploadString } from 'firebase/storage';

const storage = getStorage();

export async function uploadFile(data, fileName) {
  const storageRef = ref(storage, fileName);
  return uploadString(storageRef, data, 'data_url');
}

export async function getUrl(path) {
  try {
    return await getDownloadURL(ref(storage, path));
  } catch (e) {
    console.error(e);
  }
}

export default storage;
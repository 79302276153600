import React from 'react';
import { FormControlLabel, FormGroup, Switch } from '@material-ui/core';

export default function SwitchField({ input, label, hint, disabled, meta: { error, touched } }) {
  const isError = touched && typeof error !== 'undefined';

  return (
    <FormGroup>
      <FormControlLabel 
        checked={input.value}
        onChange={input.onChange}
        disabled={disabled}
        label={label}
        control={<Switch/>} 
      />

      {hint && <small>{hint}</small>}

      {isError && <span>{error}</span>}
    </FormGroup>
  );
}
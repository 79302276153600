import { useQuery } from "react-query";
import { ref } from "firebase/database";
import { createQuery, db } from "../util/db";

// Subscribe to pack data
export function useProducts() {
  return useQuery(
    ["tor"],
    createQuery(() => ref(db, "products/tor"))
  );
}
import React from "react";
import Section from "../../components/Section";
import SectionHeader from "../../components/SectionHeader";
import { Box, Container } from "@material-ui/core";
import ItemList from "../../lists/ItemList";
import { useAuth } from "../../util/auth";
import { useItemsByUserId } from "../../query/item";
import { Alert } from "@material-ui/lab";

export const Listing = (props) => {

  const auth = useAuth();

  const {
    data: items,
    status: itemsStatus,
    error: itemsError
  } = useItemsByUserId(auth.user?.uid);

  return (
    <Section>
      <Container>
        <SectionHeader
          title="Items"
          size={4}
          textAlign="center" 
        />

        {itemsError && (
          <Box mb={3}>
            <Alert severity="error">{itemsError.message}</Alert>
          </Box>
        )}

        <ItemList
          items={items}
          itemStatus={itemsStatus}
        />
      </Container>
    </Section>
  )
}
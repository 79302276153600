import React from "react";
import { 
  Box, 
  Button, 
  CircularProgress,
  Divider, 
  IconButton, 
  List, 
  ListItem, 
  ListItemSecondaryAction,
  ListItemText, 
  Paper, 
  Typography 
} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import { Link, useRouter } from "../util/router";
import { deleteItem } from "../actions/item";

export default function ItemList({ items, itemsStatus }) {
  const router = useRouter();

  const itemsAreEmpty = !items || Object.keys(items).length === 0;

  const handleDeleteClicked = itemId => {
    // eslint-disable-next-line no-restricted-globals
    if (confirm('Are you sure you want to delete this item?')) {
      deleteItem(itemId);
    }
  }

  return (
    <Paper>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        padding={2}
      >
        <Typography variant="h5">Items</Typography>
        
        <Button
          variant="contained"
          size="medium"
          color="primary"
          onClick={() => router.push('/items/create')}
        >
          Create Item
        </Button>
      </Box>

      <Divider />

      {(itemsStatus === "loading" || itemsAreEmpty) && (
        <Box py={5} px={3} align="center">
          {itemsStatus === "loading" && <CircularProgress size={32} />}

          {itemsStatus !== "loading" && itemsAreEmpty && (
            <>Nothing yet. Click the button to add your first item.</>
          )}
        </Box>
      )}

      {itemsStatus !== "loading" && items && items.length > 0 && (
        <List disablePadding={true}>
          {items.map((item, index) => (
            <ListItem
              key={index}
              divider={index !== items.length - 1}
            >
              <ListItemText>
                <Button to={`/items/${item.id}`} component={Link}>
                  {item.det.nm}
                </Button>
              </ListItemText>
              <ListItemText>{item.det.snm}</ListItemText>
              <ListItemSecondaryAction>
                <IconButton
                  edge="end"
                  aria-label="update"
                  onClick={() => router.push(`/items/${item.id}/edit`)}
                >
                  <EditIcon />
                </IconButton>
                <IconButton
                  edge="end"
                  aria-label="delete"
                  onClick={() => handleDeleteClicked(item.id)}
                >
                  <DeleteIcon />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          ))}
        </List>
      )}
    </Paper>
  );
}
export const required = (value) => {
  if (value === 0) return undefined;
  else return value ? undefined : 'Required';
}

export const string = (value) => value && typeof value !== 'undefined' && (typeof value !== 'string' || !(value instanceof String)) ? 'Must be plain text' : undefined;

export const number = (value) => value && typeof value !== 'undefined' && isNaN(Number(value)) ? 'Must be a number' : undefined;

export const email = (value) =>
  value && typeof value !== 'undefined' && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value) ?
    'Invalid email address' : undefined;

export const oneRequired = (value) => value && typeof value !== 'undefined' && value.length ? undefined : 'Required';

export const min = (min) => (value) => value && typeof value !== 'undefined' && value.length < min ? `Must be at least ${min} characters long.` : undefined;

export const max = (max) => (value) => value && typeof value !== 'undefined' && value.length > max ? `Cant be more than ${max} characters long.` : undefined;

export const composeValidators = (...validators) => value =>
  validators.reduce((error, validator) => error || validator(value), undefined);

const validators = {
  required,
  number,
  string,
  email,
  oneRequired,
  min,
  max
}

export const validate = (ruleSet) => value => {
  const rules = ruleSet.split('|');

  const selectedValidators = [];
  
  rules.forEach(rule => {
    if (rule.includes(':')) {
      const [validator, options] = rule.split(':');

      if (validators[validator]) {
        selectedValidators.push(validators[validator](options));
      } else {
        console.error(`rule: ${rule} not defined`);
      }
    } else {
      if (validators[rule]) {
        selectedValidators.push(validators[rule]);
      } else {
        console.error(`rule: ${rule} not defined`);
      }
    }
  });

  return selectedValidators.reduce((error, validator) => error || validator(value), undefined);
}

export default validators;
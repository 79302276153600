import React from 'react';

export default function CheckboxField({ input, label, meta: { error, touched } }) {
  const isError = touched && typeof error !== 'undefined';

  return <div className="mb-4">
    <input
      type="checkbox"
      value={input.value}
      onChange={input.onChange} 
    />
    {label && <label>{label}</label>}

    {isError && <span>{error}</span>}
  </div>
}
import React from "react";
import Section from "../../components/Section";
import SectionHeader from "../../components/SectionHeader";
import { Box, Container } from "@material-ui/core";
import PackList from "../../lists/PackList";
import { useAuth } from "../../util/auth";
import { usePacksByUserId } from "../../query/pack";
import { Alert } from "@material-ui/lab";

export const Listing = (props) => {

  const auth = useAuth();

  const {
    data: packs,
    status: packsStatus,
    error: packsError
  } = usePacksByUserId(auth.user?.uid);

  return (
    <Section>
      <Container>
        <SectionHeader
          title="Packs"
          size={4}
          textAlign="center" 
        />

        {packsError && (
          <Box mb={3}>
            <Alert severity="error">{packsError.message}</Alert>
          </Box>
        )}

        <PackList
          packs={packs}
          packStatus={packsStatus}
        />
      </Container>
    </Section>
  )
}
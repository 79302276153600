import { Button, Grid, Typography } from "@material-ui/core";
import React from "react";
import { Field, Form } from "react-final-form";
import * as validators from '../util/validators';
import RenderField from "../components/RenderField";
import { useToursByUserId } from "../query/tour";
import { useAuth } from "../util/auth";

export default function PackForm({ onSubmit, pack, tourId }) {
  const auth = useAuth();
  const { data: tours } = useToursByUserId(auth.user?.uid);

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={pack ? { ...pack } : {}}
      render={({ handleSubmit }) => {
        return (
          <form onSubmit={handleSubmit}>
            <Grid container spacing={4}>
              {!pack && tourId && <Grid item xs={12}>
                <Typography variant="h5">Create a pack for {tours.find(t => t.id === tourId)?.det.nm}</Typography>
              </Grid>}

              <Grid item xs={12}>
                <Field
                  name="det.nm"
                  component={RenderField}
                  type="text"
                  placeholder="Name"
                  label="Name"
                  validate={validators.required}
                />
              </Grid>

              <Grid item xs={12}>
                <Button 
                  type="submit" 
                  variant="contained"
                  size="medium"
                  color="primary"
                >
                  {pack ? 'Save' : 'Create'}
                </Button>
              </Grid>
            </Grid>
          </form>
        );
      }} 
    />
  );
}
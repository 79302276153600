import React from "react";
import { Button, FormGroup, FormLabel, Grid } from "@material-ui/core";
import { Field, Form } from "react-final-form";
import * as validators from '../util/validators';
import RenderField from "../components/RenderField";
import ImageUpload from "../components/ImageUpload";
import { HexColorPicker } from "react-colorful";

export default function ItemForm({ onSubmit, item }) {
  return (
    <Form
      onSubmit={onSubmit}
      initialValues={item ? { 
        ...item
      } : {}}
      render={({ handleSubmit, form, values }) => {
        const handleImageUploaded = (path, fieldName) => {
          form.change(fieldName, path);
        }

        return (
          <form onSubmit={handleSubmit}>
            <Grid container spacing={4}>
              <Grid item xs={12}>
                <Field
                  name="det.nm"
                  component={RenderField}
                  type="text"
                  placeholder="Name"
                  label="Name"
                  validate={validators.required}
                />
              </Grid>

              <Grid item xs={12}>
                <FormGroup>
                  <FormLabel>Colour</FormLabel>

                  <HexColorPicker 
                    color={values.det?.col} 
                    onChange={v => form.change('det.col', v)} 
                  />
                </FormGroup>
              </Grid>

              <Grid item xs={12}>
                <Field
                  name="det.dM"
                  component={RenderField}
                  type="switch"
                  label="Metric?"
                />
              </Grid>

              <Grid item xs={12}>
                <Field
                  name="det.dW"
                  component={RenderField}
                  type="number"
                  placeholder="Weight"
                  label={`Weight ${values.det?.dM ? 'kg' : 'pounds'}`}
                  validate={validators.required}
                />
              </Grid>

              <Grid item xs={12}>
                <Field
                  name="det.dX"
                  component={RenderField}
                  type="number"
                  placeholder="Width (X)"
                  label={`Width (X) ${values.det?.dM ? 'mm' : 'inches'}`}
                  validate={validators.required}
                />
              </Grid>

              <Grid item xs={12}>
                <Field
                  name="det.dY"
                  component={RenderField}
                  type="number"
                  placeholder="Height (Y)"
                  label={`Height (Y) ${values.det?.dM ? 'mm' : 'inches'}`}
                  validate={validators.required}
                />
              </Grid>

              <Grid item xs={12}>
                <Field
                  name="det.dZ"
                  component={RenderField}
                  type="number"
                  placeholder="Depth (Z)"
                  label={`Depth (Z) ${values.det?.dM ? 'mm' : 'inches'}`}
                  validate={validators.required}
                />
              </Grid>

              <Grid item xs={12}>
                <ImageUpload
                  label="Image"
                  placeholder={item?.det?.pURL}
                  onFileUploaded={url => {
                    handleImageUploaded(url, 'pURL')
                  }}
                />
              </Grid>

              <Grid item xs={12}>
                <Button 
                  type="submit" 
                  variant="contained"
                  size="medium"
                  color="primary"
                >
                  {item ? 'Save' : 'Create'}
                </Button>
              </Grid>
            </Grid>
          </form>
        );
      }} 
    />
  );
}
import React from "react";
import Section from "../../components/Section";
import { Box, CircularProgress, Container } from "@material-ui/core";
import SectionHeader from "../../components/SectionHeader";
import { useItemById } from "../../query/item";
import { useParams } from "react-router-dom";
import { Alert } from "@material-ui/lab";
import DetailList from "../../components/DetailList";
import { Stack } from "@mui/material";
import AutoImage from "../../components/AutoImage";

export const View = () => {
  
  const params = useParams();

  const {
    data: item,
    status: itemStatus,
    error: itemError
  } = useItemById(params.id);

  return (
    <Section>
      <Container>
        {(itemStatus === "loading" || !item) && (
          <Box py={5} px={3} align="center">
            {itemStatus === "loading" && <CircularProgress size={32} />}

            {itemStatus !== "loading" && (
              <>Nothing yet. Click the button to add your first item.</>
            )}
          </Box>
        )}

        {itemError && (
          <Box mb={3}>
            <Alert severity="error">{itemError.message}</Alert>
          </Box>
        )}

        {itemStatus === 'success' && item && (
          <>
            <SectionHeader
              title={item.det.nm}
              size={4}
              textAlign="center" 
            />

            <Stack gap={4}>
              {item.det.pURL && (
                <Stack justifyContent="center" direction="row">
                  <Container maxWidth="sm">
                    <AutoImage src={item.det.pURL} maxWidth="10rem" />
                  </Container>
                </Stack>
              )}

              <DetailList
                items={[
                  { label: "Colour",      value: item.det.col },
                  { label: "Weight",      value: item.det.dW },
                  { label: "Width (X)",   value: item.det.dX },
                  { label: "Height (Y)",  value: item.det.dY },
                  { label: "Depth (Z)",   value: item.det.dZ },
                ]}
              />
            </Stack>
          </>
        )}
      </Container>
    </Section>
  );
}
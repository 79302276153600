import React from "react";
import { 
  Box,
  Modal as MaterialModal
} from "@material-ui/core";

export default function Modal({ children, open, onClose }) {

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '90%',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };
  
  return (
    <MaterialModal open={open} onClose={onClose}>
      <Box sx={style}>
        {children}
      </Box>
    </MaterialModal>
  );
}
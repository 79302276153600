import { ref, remove, serverTimestamp, push, set } from "firebase/database";
import { db } from "../util/db";

export function createPack(data) {
  return push(ref(db, "packs"), {
    ...data,
    createdAt: serverTimestamp(),
  });
}

export function updatePack(id, data) {
  return set(ref(db, "packs/"+id), data);
}

export function deletePack(id) {
  return remove(ref(db, "packs/"+id));
}